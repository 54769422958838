<template>
  <div class="admin-detail">
    <div class="admin-navbar justify-between">
      <button class="action-button" @click="$router.go(-1)">{{ $lang.app.back }}</button>
    </div>
    <div v-if="helpItem" class="admin-info">
      <h4 class="info-title">{{ $lang.app.help }}</h4>
      <div class="info-fields">
        <div class="info-field">
          <p class="field-label">{{ $lang.app.category }}:</p>
          <p class="field-value">{{ typeLabel(helpItem.organizationType) }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.content_type }}:</p>
          <p class="field-value">{{ helper.types.find(type => type.value === helpItem.type).label }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.heading }}:</p>
          <p class="field-value">{{ $lang.$translate({ru: helpItem.titleRu, kg: helpItem.titleKg}) }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.content }}:</p>
          <p class="field-value">
            <a href @click.prevent="openFile(helpItem.filename)">
              {{ helpItem.filename }}
            </a>
            <a v-if="helpItem.type === 'URL'" :href="helpItem.url" target="_blank">
              {{ helpItem.url }}
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fileUploadMixin from "../../../mixins/fileUploadMixin";

export default {
  mixins: [fileUploadMixin],
  data() {
    return {
      helper: {
        types: [
          {
            value: 'DOC',
            label: this.$lang.app.document,
          },
          {
            value: 'URL',
            label: this.$lang.app.link,
          }
        ]
      },
      helpItem: null,
    }
  },
  methods: {
    typeLabel(value) {
      switch (value) {
        case 'SCHOOL': return this.$lang.app.school
        case 'PRESCHOOL': return this.$lang.app.preschool
      }
    },
    fetchResource() {
      this.$axios.get(`/manual/${this.$route.params.id}`).then(({data}) => {
        this.helpItem = data
      })
    },
    openFile(filename) {
      this.downloadFile(filename).then(data => {
        let extension = filename.split('.').pop().toLowerCase();
        if(['png', 'jpg', 'jpeg'].includes(extension)) {
          let file = URL.createObjectURL(new Blob([data],{type: `image/${extension}`}));
          window.open(file, '_blank');
        }
        if(extension === 'pdf') {
          let file = URL.createObjectURL(new Blob([data],{type: 'application/pdf'}));
          window.open(file, '_blank');
        }
      })
    },
  },
  mounted() {
    this.fetchResource()
  }
}
</script>
<style>
@import '../../../assets/styles/admin/detail.css';
@import '../../../assets/styles/admin/info.css';
@import '../../../assets/styles/admin/navbar.css';
</style>